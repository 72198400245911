<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <br>
      <p>
        <b>По&nbsp;вопросам начисления суммы призов:</b><br>
        <a href="mailto:oleg_shtynda@rgs.ru">oleg_shtynda@rgs.ru</a>
      </p>
      <p><b>По&nbsp;вопросам подарочных сертификатов и&nbsp;заполнению документов:</b><br>
      Email: <a href="mailto:support@giftery.ru">support@giftery.ru</a><br>
      Горячая линия: <a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a> (<nobr>пн-пт</nobr> с&nbsp;9 до&nbsp;18)</p>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
