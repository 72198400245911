<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Уважаемые партнеры!</h1>
          <h2>Мы рады приветствовать Вас на нашем сайте Программы Мотивации</h2>
        </div>
      </div>
      <hr>

      <p>С помощью мотивационной программы мы хотим выразить благодарность за активное сотрудничество!</p>

      <h3>Вознаграждение</h3>
      <p>Вознаграждением является Подарочная карта myGift.</p>
      <p>На балансе в Вашем Личном кабинете будем сумма, равная сумме приза за конкурс.</p>
      <p>При достижении суммы приза в текущем календарном году более 4000 рублей Вам необходимо будет заполнить Анкету и приложить документы для декларации дохода и уплате подоходного налога. Налог уплачивается за счет организатора конкурса — ПАО СК «Росгосстрах» (Вам не придется подавать декларацию о доходе самостоятельно). После проверки документов (7 рабочих дней с момент загрузки полного комплекта документов), необходимых для уплаты подоходного налога,  Подарочная карта myGift будет активирована в течение 1–2 рабочих дней.</p>
      <p><b>Мы&nbsp;всегда рады ответить на&nbsp;Ваши вопросы:</b></p>
      <p>
        <b>По&nbsp;вопросам начисления суммы призов:</b><br>
        Email: <a href="mailto:oleg_shtynda@rgs.ru">oleg_shtynda@rgs.ru</a>
      </p>
      <p><b>По&nbsp;вопросам подарочных сертификатов и&nbsp;заполнению документов:</b><br>
      Email: <a href="mailto:support@giftery.ru">support@giftery.ru</a><br>
      Горячая линия: <a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a> (<nobr>пн-пт</nobr> с&nbsp;9 до&nbsp;18)</p>
      <br>
      <router-link class="button button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>
